<template>
  <VueHeader></VueHeader>
  <VueMain></VueMain>
  <VueFooter></VueFooter>
</template>

<script>
import VueHeader from './components/Header.vue'
import VueMain from './components/Main.vue'
import VueFooter from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    VueHeader,
    VueMain,
    VueFooter
  },
  mounted() {
  }}

let vh = window.innerHeight * 0.01;

document.documentElement.style.setProperty("--vh", `${vh}px`);

</script>

<style>
body {
  background-color: black;
  margin: 0px;
  height: 100vh;
  width: 100vw;
  background-image: url('./assets/img/background-1920.png');
  background-repeat: no-repeat;
  background-size: cover;
}

#app {
  font-family: 'milkyway', 'sogang', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: rgb(36, 6, 75);
  
  min-height: calc(var(--vh, 1vh) *100);
  
}

@font-face {
    font-family: 'sogang';
    src: url('./assets/fonts/SOGANG.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'sogang-bold';
    src: url('./assets/fonts/SOGANG.ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'milkyway';
    src: url('./assets/fonts/TAEBAEK milkyway.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'milkyway-bold';
    src: url('./assets/fonts/TAEBAEK milkyway.ttf');
    font-weight: bold;
    font-style: normal;
}

:root {
  --fc-today-bg-color : rgba(255, 117, 147, 0.226);
}

</style>
