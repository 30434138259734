<template>
    <header class="header">
        <img alt="logo" src="../assets/plave_pli.png">
    </header>
</template>

<script>
export default {
    name: 'VueHeader'
}

</script>

<style scoped>
img {
    opacity:0;
    width: auto;
    height: 10vh;
    object-fit : cover;
}

</style>