<template>
    <div class="calendar">
        <div class="popup-bg" v-show=ShowVideo @click="changePopupState()">
            <ModalPopup v-show=ShowVideo @close="changePopupState()" :modalData="modalData" :ShowVideo="ShowVideo">
            </ModalPopup>
        </div>
        <FullCalendar :options='calendarOptions'>
            <template v-slot:eventContent='arg'>
                <div class="title-wrapper">
                    <div class="live-title" >
                        {{ arg.event.title }}
                    </div>
                </div>
            </template>
        </FullCalendar>
    </div>
</template>
<script setup>
</script>

<script>
import ModalPopup from './ModalPopup.vue'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import Lives from '../assets/Dataset/Live.json'
import Radios from '../assets/Dataset/Radio.json'
import Specials from '../assets/Dataset/Special.json'
import { ref} from 'vue'

///// Data /////
let mem = ["", "💙", "💜", "💗", "❤️", "🖤", "🤍"]
let ShowVideo = ref('')
let modalData = ref('')

function ChangeSpecialDays(date) {
    if (date === "2024-04-01") {
        return "🧡💜💗🤎💖";
    }

    return null;
}

const LiveList = Lives.map((value) => {
    let title = '';
    value.member.map((num) => {
        title += mem[num];
    });

    title = ChangeSpecialDays(value.date) ?? title;

    return { "title": title, "start": new Date(value.date), "link": value.link, name: value.name };
});

const RadioList = Radios.map((value) => {
    let title = '📻' + value.program;
    value.member.map((num) => {
        title += mem[num];
    })

    return { "title": title, "start": new Date(value.date), "link": value.link, name: value.name };
});

const SpecialList = Specials.map((value) => {
    let title = value.program;
    if (value.member) {
        value.member.map((num) => {
            title += mem[num];
        });
    }

    return {title : title, start: new Date(value.start_date), end: new Date(value.end_date), link: value.link, name: value.name};
});

const EventList = [...SpecialList, ...RadioList, ...LiveList];

function ShowLink(arg) {
    ShowVideo.value = arg.event.extendedProps.link ? true : null;
    if (ShowVideo.value !== null) {
        modalData.value = {
            youtubeLink : "https://www.youtube.com/live/" + arg.event.extendedProps.link,
            videoLink : "https://www.youtube.com/embed/" + arg.event.extendedProps.link,
            name: arg.event.extendedProps.name
        };
    }
}

function changePopupState() {
    ShowVideo.value = false;
}

function AddTitleAnimateEvent() {
    let live_titles = document.getElementsByClassName('live-title');
            for (let i = 0; i < live_titles.length; i++) {
                let title = live_titles[i];
                if (title.scrollWidth > title.clientWidth) {
                    title.classList.add("animate");
                }
            }
}

function EventMounted() {}

///// Data /////

//// css ///////

//// css ///////

export default {
    name: 'VueCalendar',
    components: {
        FullCalendar,
        ModalPopup
    },
    mounted() {
        window.addEventListener("load", () => {
            let prevButton = document.getElementsByClassName('fc-prev-button');
            let nextButton = document.getElementsByClassName('fc-next-button');
            prevButton[0].addEventListener('click', function (event) {event;AddTitleAnimateEvent();});
            nextButton[0].addEventListener('click', function (event) {event;AddTitleAnimateEvent();});
            AddTitleAnimateEvent();
        });
    },
    data: function () {
        return {
            ShowVideo,
            changePopupState,
            modalData,
            calendarOptions: {
                plugins: [dayGridPlugin],
                initialView: 'dayGridMonth',
                weekends: true,
                headerToolbar: {
                    start: 'title',
                    end: 'prev,next'
                },
                contentHeight:"auto",
                titleFormat: { year: 'numeric', month: 'short' },
                eventClick: ShowLink,
                events: EventList,
                eventDidMount: EventMounted
            }
        }
    }
}

</script>

<style>
.calendar {
    /* background-color: aliceblue; */
    margin-left: 2vw;
    margin-right: 2vw;
    padding: 2vw;
}
.calendar .fc-daygrid-day-number {
  background: linear-gradient(to right, #1b0015, #1e50a1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'sogang';
}

.calendar .fc-col-header {
  background: linear-gradient(to right, #1b0015, #1e50a1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fc .fc-toolbar-title {
    padding-left: 3vw;
    font-size: 3vh;

    background: linear-gradient(to right, #1b0015, #1e50a1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fc-daygrid-event {
  white-space: normal !important;
  align-items: normal !important;
}

.fc table {
    font-size: 1.5vh;
    background-color: #ffffff;
}

.fc .fc-button {
    /* padding: 0.2em 0.2em; */
}

@media screen and (min-width: 800px) {
.live-title {
    font-size: 16px;
  }
}

.title-wrapper {
    white-space: nowrap;
    overflow: hidden;
}

.live-title {
    display: flex;
    /* flex: 0 0 auto; */
    white-space: nowrap;
    overflow: hidden;
    transition: 0.3s;
    padding-left: 1px;
    padding-right: 1px;
    will-change: transform;
}

.animate {
    display: inline-block;
    position: relative;
    animation: 5s leftright infinite alternate ease-in-out;
}

@keyframes leftright {
  0%,
  20%,
  25% {
    transform: translateX(0%) perspective(500px) rotate(.001deg);
    left: 0%;
  }
  75%,
  80%,
  100% {
    transform: translateX(-100%) perspective(500px) rotate(.001deg);
    left: 100%;
  }
}

.red {
    background: rgb(235, 77, 77) !important;
    color: whitesmoke !important;
}

.blue {
    background: rgb(59, 59, 163) !important;
    color: whitesmoke !important;
}

.orange {
    background: orange !important;
    color: white !important;
}

.green {
    background: rgb(49, 155, 49) !important;
    color: white !important;
}

.blue,
.orange,
.red,
.green {
    font-size: 2vh;
    font-weight: 500;
    text-transform: capitalize;
}

.event-item {
    padding: 2px 0 2px 4px !important;
}

.popup-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

</style>