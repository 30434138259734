<template>
    <footer class="footer">
        천천히 업뎃 & 개발.. 놓친게 너무 많아 슬픈 늦덕의 개인용.. /
        사진들 출처는 <a href="https://www.youtube.com/@plave_official">https://www.youtube.com/@plave_official</a> 여긴데 혹시
        문제되면 말씀해주세요../
        혹시 문의가 있다면 @ddogye로 부탁.. /
        last update : 2024-06-09
    </footer>
</template>

<script>
export default {
    name: 'VueFooter'
}

</script>

<style scoped>
footer {
    position: relative;
    transform: translateY(-100%);
    font-size: 1vh;
    height: 1vh;
}
</style>