<template>
    <div id="wrapper">
        <VueCalendar></VueCalendar>
    </div>

</template>

<script setup>

import VueCalendar from './Calendar.vue'
</script>
<script>
export default {
    name: 'VueMain'
}

let vh = window.innerHeight * 0.01;

document.documentElement.style.setProperty("--vh", `${vh}px`);

</script>

<style scoped>
#wrapper {
    width: auto;
    min-height: calc(var(--vh, 1vh) *86);
    height : auto;
    padding-bottom: 2vh;
  }
</style>