<template>
    <Transition name="display-video">
        <div class="modal-popup" :class="{ active: ShowVideo }">
            <div class="popup-contents-wrapper">
                <div class="link">
                    <a :href=youtubeLink> {{ this.modalData.name ?? "💙💜💗❤️🖤" }} </a>
                </div>
                <iframe id='video' :class="{ active: loaded }" :width=width :height=height :src=videoLink
                    title="YouTube video player" frameborder="0" @onload="siteLoaded()"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
    </Transition>
</template>

<script>

import { ref, onUpdated } from 'vue'

let videoLink = ref('')
let youtubeLink = ref('')
let loaded = ref('')

onUpdated(() => {
    loaded = true;
})

export default {
    computed: {
        src() {
            return videoLink
        },
        // default : 560 : 315
        width() {
            let windowWidth;
            
            if (window.innerWidth >= 768)
                windowWidth = "694";
            else
                windowWidth = "280";

            return windowWidth;
        },
        height() {
            let windowHeight;
            if (window.innerWidth >= 768)
            windowHeight = "390";
            else
            windowHeight = "157";
            return windowHeight;
        }
    },
    methods: {
        siteLoaded() {
            loaded = true;
        }
    },
    props: ['modalData', 'ShowVideo'],
    data() {
        return {
            loaded,
            youtubeLink,
            videoLink,
            popupState: false
        }
    },
    watch: {
        modalData() {
            videoLink.value = this.modalData.videoLink;
            youtubeLink.value = this.modalData.youtubeLink;
            loaded = false;
        },
        ShowVideo() {
        }
    }

}
</script>

<style>
.popup-contents-wrapper {
    position:relative;
}

.modal-popup {
    background-repeat: no-repeat;
    background-size: cover;
    width: 0px;
    height: 0px;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    transition: all 3s ease;

    &.active {
        display: inline-block;
        align-items: center;
    }
}

#video {
    position: absolute;
    animation: fadeIn 3s forwards;

}

.link {
    font-size: 1.5vh;
    position: absolute;
    margin-left: 10px;
    color:black;
    display:flex;
    text-align:left;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .modal-popup {
        background-image: url("../assets/img/popup-mobile.png");

        &.active {
            width: 300px;
            height: 225px;
        }
    }
        
    #video {
        top: 56px;
        left: 10px;
    }

    .link {
        width: 250px;
        height: 47px;
    }

}

@media screen and (min-width: 768px) {
    .modal-popup {
        background-image: url("../assets/img/popup-notebook.png");

        &.active {
            width: 751px;
            height: 499px;
        }
    }
    
    
    #video {
        top: 79px;
        left: 29px;
    }
    
    .link {
        width: 620px;
        height: 54px;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.link > a {
    font-family: 'sogang-bold';
    color: inherit;
    text-decoration:none;
 }

.display-video-enter-active,
.display-video-leave-active {
    transition: all 0.5s ease;
    max-width: 751px;
    max-height: 572px;
}

.display-video-enter-from,
.display-video-leave-to {
    opacity: 0;
    max-width: 0px;
    max-height: 0px;
}
</style>